import brand from './brand';

const colors = {
  transparent: 'transparent', // 0
  black: brand.brand8, // 1
  white: '#ffffff', // 2
  headingColor: brand.brand8,
  textColor: brand.brand8Tint10, // 3
  labelColor: brand.brand8Tint40, // 4
  inactiveField: brand.brand8Tint60, // 5
  inactiveButton: brand.brand8Tint60, // 6
  inactiveIcon: brand.brand8Tint60, // 7
  primary: brand.brand6, // 8
  primaryHover: brand.brand7Shade10, // 9
  secondary: brand.brand5, // 10
  secondaryHover: brand.brand5Shade10, // 11
  yellow: brand.brand1, // 12
  yellowHover: brand.brand1Shade10, // 13
  borderColor: brand.brand8Tint60, //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(42, 162, 117, 0.57)',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(237, 205, 55, 0.5)',
};

export default colors;
