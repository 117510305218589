const brand = {
  // #1
  brand1: '#e6ff0f',
  // Shades
  brand1Shade10: '#cfe60e',
  brand1Shade20: '#b8cc0c',
  brand1Shade30: '#a1b30b',
  brand1Shade40: '#8a9909',
  brand1Shade50: '#738008',
  brand1Shade60: '#5c6606',
  brand1Shade70: '#454c04',
  brand1Shade80: '#2e3303',
  brand1Shade90: '#171901',
  // Tints
  brand1Tint10: '#e9ff27',
  brand1Tint20: '#ebff3f',
  brand1Tint30: '#eeff57',
  brand1Tint40: '#f0ff6f',
  brand1Tint50: '#f3ff87',
  brand1Tint60: '#f5ff9f',
  brand1Tint70: '#f8ffb7',
  brand1Tint80: '#faffcf',
  brand1Tint90: '#fdffe7',

  // #2
  brand2: '#88ff28',
  // Shades
  brand2Shade10: '#7ae624',
  brand2Shade20: '#6dcc20',
  brand2Shade30: '#5fb31c',
  brand2Shade40: '#529918',
  brand2Shade50: '#448014',
  brand2Shade60: '#366610',
  brand2Shade70: '#294c0c',
  brand2Shade80: '#1b3308',
  brand2Shade90: '#0e1904',
  // Tints
  brand2Tint10: '#94ff3e',
  brand2Tint20: '#a0ff53',
  brand2Tint30: '#acff69',
  brand2Tint40: '#b8ff7e',
  brand2Tint50: '#c4ff94',
  brand2Tint60: '#cfffa9',
  brand2Tint70: '#dbffbf',
  brand2Tint80: '#e7ffd4',
  brand2Tint90: '#f3ffea',

  // #3
  brand3: '#00f5be',
  // Shades
  brand3Shade10: '#00ddab',
  brand3Shade20: '#00c498',
  brand3Shade30: '#00ac85',
  brand3Shade40: '#009372',
  brand3Shade50: '#007b5f',
  brand3Shade60: '#00624c',
  brand3Shade70: '#004939',
  brand3Shade80: '#003126',
  brand3Shade90: '#001813',
  // Tints
  brand3Tint10: '#1af6c5',
  brand3Tint20: '#33f7cb',
  brand3Tint30: '#4df8d2',
  brand3Tint40: '#66f9d8',
  brand3Tint50: '#80fadf',
  brand3Tint60: '#99fbe5',
  brand3Tint70: '#b3fcec',
  brand3Tint80: '#ccfdf2',
  brand3Tint90: '#e6fef9',

  // #4
  brand4: '#00c8ff',
  // Shades
  brand4Shade10: '#00b4e6',
  brand4Shade20: '#00a0cc',
  brand4Shade30: '#008cb3',
  brand4Shade40: '#007899',
  brand4Shade50: '#006480',
  brand4Shade60: '#005066',
  brand4Shade70: '#003c4c',
  brand4Shade80: '#002833',
  brand4Shade90: '#001419',
  // Tints
  brand4Tint10: '#1aceff',
  brand4Tint20: '#33d3ff',
  brand4Tint30: '#4dd9ff',
  brand4Tint40: '#66deff',
  brand4Tint50: '#80e4ff',
  brand4Tint60: '#99e9ff',
  brand4Tint70: '#b3efff',
  brand4Tint80: '#ccf4ff',
  brand4Tint90: '#e6faff',

  // #5
  brand5: '#2950ff',
  // Shades
  brand5Shade10: '#2548e6',
  brand5Shade20: '#2140cc',
  brand5Shade30: '#1d38b3',
  brand5Shade40: '#193099',
  brand5Shade50: '#152880',
  brand5Shade60: '#102066',
  brand5Shade70: '#0c184c',
  brand5Shade80: '#081033',
  brand5Shade90: '#040819',
  // Tints
  brand5Tint10: '#3e62ff',
  brand5Tint20: '#5473ff',
  brand5Tint30: '#6985ff',
  brand5Tint40: '#7f96ff',
  brand5Tint50: '#94a8ff',
  brand5Tint60: '#a9b9ff',
  brand5Tint70: '#bfcbff',
  brand5Tint80: '#d4dcff',
  brand5Tint90: '#eaeeff',

  // #6
  brand6: '#963dff',
  // Shades
  brand6Shade10: '#8737e6',
  brand6Shade20: '#7831cc',
  brand6Shade30: '#692bb3',
  brand6Shade40: '#5a2599',
  brand6Shade50: '#4b1f80',
  brand6Shade60: '#3c1866',
  brand6Shade70: '#2d124c',
  brand6Shade80: '#1e0c33',
  brand6Shade90: '#0f0619',
  // Tints
  brand6Tint10: '#a150ff',
  brand6Tint20: '#ab64ff',
  brand6Tint30: '#b677ff',
  brand6Tint40: '#c08bff',
  brand6Tint50: '#cb9eff',
  brand6Tint60: '#d5b1ff',
  brand6Tint70: '#e0c5ff',
  brand6Tint80: '#ead8ff',
  brand6Tint90: '#f5ecff',

  // #7
  brand7: '#5528d8',
  // Shades
  brand7Shade10: '#4d24c2',
  brand7Shade20: '#4420ad',
  brand7Shade30: '#3b1c97',
  brand7Shade40: '#331882',
  brand7Shade50: '#2b146c',
  brand7Shade60: '#221056',
  brand7Shade70: '#190c41',
  brand7Shade80: '#11082b',
  brand7Shade90: '#080416',
  // Tints
  brand7Tint10: '#663edc',
  brand7Tint20: '#7753e0',
  brand7Tint30: '#8869e4',
  brand7Tint40: '#997ee8',
  brand7Tint50: '#aa94ec',
  brand7Tint60: '#bba9ef',
  brand7Tint70: '#ccbff3',
  brand7Tint80: '#ddd4f7',
  brand7Tint90: '#eeeafb',

  // #8
  brand8: '#2b3d4d',
  // Shades
  brand8Shade10: '#273745',
  brand8Shade20: '#22313e',
  brand8Shade30: '#1e2b36',
  brand8Shade40: '#1a252e',
  brand8Shade50: '#161f27',
  brand8Shade60: '#11181f',
  brand8Shade70: '#0d1217',
  brand8Shade80: '#090c0f',
  brand8Shade90: '#040608',
  // Tints
  brand8Tint10: '#40505f',
  brand8Tint20: '#556471',
  brand8Tint30: '#6b7782',
  brand8Tint40: '#808b94',
  brand8Tint50: '#959ea6',
  brand8Tint60: '#aab1b8',
  brand8Tint70: '#bfc5ca',
  brand8Tint80: '#d5d8db',
  brand8Tint90: '#eaeced',
};

export default brand;
